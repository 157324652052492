<template>
  <div style="overflow:hidden">
    <!-- 所属组织 -->
    <van-popup v-model="branchShow" round position="bottom">
       <van-cascader title="请选择所属组织" :options="branchList" :field-names="branchNames" @change="branchChange" @close="handleBranchClose"/>
    </van-popup>
    <!-- 党员类型 -->
    <van-popup v-model="partyTypeShow" round position="bottom">
      <van-picker show-toolbar :columns="partyTypeList" title="请选择党员类型" value-key="label" @cancel="partyTypeShow = false" @confirm="onConfirm($event,1)"/>
    </van-popup>
    <!-- 党籍状态 -->
    <van-popup v-model="partyStatusShow" round position="bottom">
      <van-picker show-toolbar :columns="partyStatusList" title="请选择党籍状态" value-key="label" @cancel="partyStatusShow = false" @confirm="onConfirm($event,2)"/>
    </van-popup>
    <!-- 党内职务 -->
    <van-popup v-model="postShow" round position="bottom">
      <van-picker show-toolbar :columns="postList" title="请选择党内职务" value-key="name" @cancel="postShow = false" @confirm="onConfirm($event,3)"/>
    </van-popup>
    <!-- 入党日期 -->
    <van-popup v-model="joinDateShow" round position="bottom">
      <van-datetime-picker type="date" title="选择入党日期" :min-date="minDate" @cancel="joinDateShow = false" @confirm="onConfirm($event,4)"/>
    </van-popup>
    <!-- 转正日期 -->
    <van-popup v-model="correctionDateShow" round position="bottom">
      <van-datetime-picker type="date" title="选择转正日期" :min-date="minDate" @cancel="correctionDateShow = false" @confirm="onConfirm($event,5)"/>
    </van-popup>
    <!-- 入支时间 -->
    <van-popup v-model="joinBranchDateShow" round position="bottom">
      <van-datetime-picker type="date" title="选择入支时间" :min-date="minDate" @cancel="joinBranchDateShow = false" @confirm="onConfirm($event,6)"/>
    </van-popup>
    <!-- 党员标签 -->
    <van-popup v-model="labelShow" position="bottom" style="max-height: 50%;">
      <div class="typeCheck">
        <span @click="labelShow = false">取消</span>
        <span>请选择</span>
        <span @click="typeConfirm">确定</span>
      </div>
      <van-checkbox-group class="typeCheckBox" @change="change" v-model="checkedValue">
        <van-cell-group v-for="(item, index) in labelList" :key="index">
          <van-cell  :title="item.label"  @click="toggle(index)">
            <template #icon>
              <van-checkbox :name="item" ref="checkboxes"/><van-icon v-if="item.children !== null" :name="childrenVisible ?'arrow-up':'arrow-down'" @click.stop="childrenVisible = !childrenVisible"/>
            </template>
          </van-cell>
          <van-checkbox-group v-if="childrenVisible" class="children" @change="childrenChange" v-model="childrenValue">
            <van-cell-group v-for="(items, i) in item.children" :key="i">
              <van-cell  :title="items.label"  @click="toggleChildren(i)">
                <template #icon>
                  <van-checkbox :name="items" ref="childrenBoxes"/>
                </template>
              </van-cell>
            </van-cell-group>
          </van-checkbox-group>
        </van-cell-group>
      </van-checkbox-group>
      <!-- <res-label :list="labelList" @selectLabel="selectLabel" ref="resLabel"></res-label> -->
    </van-popup>

    <van-cell-group>
      <van-cell>
        <template #title>
          <!-- <div class="blue-box" style="background-color: #F24C48"></div> -->
          <div class="field-title">
            <span v-if="!isEdit" class="custom-title">{{id ? '查看' : '新增'}}</span>
            <span v-if="isEdit" class="custom-title">编辑</span>
            <span v-if="id && !isEdit" class="edit" @click="handleIsEdit">编辑</span>
          </div>
        </template>
      </van-cell>
      <van-field input-align="right" v-model="dataForm.idNumber" required :readonly="readonly" label="党员身份证" placeholder="请输入" @blur="getUserId"/>
      <van-field input-align="right" v-model="dataForm.branchName" required is-link readonly label="所属组织" :class=" readonly ? 'poinrt' : ''" placeholder="请选择" @click="branchShow = true"/>
      <van-field input-align="right" :value="dataForm.partyType === 1 ? '正式党员' : dataForm.partyType === 2?  '预备党员' : ''" required is-link readonly label="党员类型" :class=" readonly ? 'poinrt' : ''" placeholder="请选择" @click="partyTypeShow = true"/>
      <van-field input-align="right" :value="dataForm.partyStatus === 0 ? '正常' : dataForm.partyStatus === 1 ? '停止' : ''" required is-link readonly label="党籍状态" :class=" readonly ? 'poinrt' : ''" placeholder="请选择" @click="partyStatusShow = true"/>
      <van-field input-align="right" v-model="dataForm.joinDate" required is-link readonly label="入党日期" :class=" readonly ? 'poinrt' : ''" placeholder="请选择" @click="joinDateShow = true"/>
      <van-field input-align="right" :value="labelStr" required is-link readonly label="党员标签" :class=" readonly ? 'poinrt' : ''" placeholder="请选择" @click="labelShow = true"/>
      <van-field input-align="right" v-model="dataForm.postName" required is-link readonly label="党内职务" :class=" readonly ? 'poinrt' : ''" placeholder="请选择" @click="postShow = true"/>
      <van-field input-align="right" label="是否流动党员" readonly:class=" readonly ? 'poinrt' : ''" placeholder="请选择">
        <!-- <van-switch v-model="dataForm.isFlow" @change="onChangeIsFlow"/> -->
        <template #input>
          <van-switch v-model="dataForm.isFlow" :active-value='1' :inactive-value='0' active-color="#ee0a24" size="20px" />
        </template>
      </van-field>
      <van-field input-align="right" v-model="dataForm.correctionDate" readonly :class=" readonly ? 'poinrt' : ''" is-link label="转正日期" placeholder="请选择" @click="correctionDateShow = true"/>
      <van-field input-align="right" v-model="dataForm.joinBranchDate" readonly :class=" readonly ? 'poinrt' : ''" is-link label="入支时间" placeholder="请选择" @click="joinBranchDateShow = true"/>
    </van-cell-group>
    <div class="detail-info" v-if="id || showInfo">
      <div class="title-wrap">
        <div class="title">个人信息</div>
        <!-- <div class="check-more">查看更多 <van-icon name="arrow" style="font-size: 14px" /></div> -->
      </div>
      <div class="info-items">
        <div class="info-title">
            <div class="left-title">
                <div class="communityName">{{dataForm.educationStr === '未知' ? '学历未知' : dataForm.educationStr ||'学历未知'}}</div>
                <!-- <div class="partyType">预备党员</div> -->
            </div>
        </div>
        <div class="info-mid">
            <div class="left-info-wrap">
                <van-image class="headImg" :src="require('@/assets/img/headImg.png')"></van-image>
                <div class="detail">
                    <div class="name">
                        {{dataForm.userName}}
                        <span class="post">({{dataForm.sex === 1 ? '男' : '女'}}/{{dataForm.age}})</span>
                    </div>
                    <div class="number">{{dataForm.mobile}}</div>
                    <div class="number">{{dataForm.idNumber}}</div>
                </div>
            </div>
            <van-icon class="phoneIcon" name="arrow" />
        </div>
      </div>
    </div>
    <div class="footer-btns">
        <van-button v-if="!id" type="info" size="large" @click="submit" color="#F24C48" style="border-radius: 10px">确认添加</van-button>
        <div v-else class="button-group">
          <van-button v-if="!isEdit" class="delete" type="info" size="large" style="border-radius: 10px" @click="moveOutParty('delete')">删除</van-button>
          <van-button class="btn-items" v-if="!isEdit" size="large" type="info" color="#F24C48" style="border-radius: 10px" @click="moveOutParty('moveOut')"> 迁出</van-button>
          <!-- <van-button class="btn-items" v-else type="info" size="large" color="#F24C48" style="border-radius: 10px" @click="handleEidt">完成</van-button> -->
        </div>
        <van-button v-if="isEdit" type="info" size="large" @click="handleEidt" color="#F24C48" style="border-radius: 10px">完成</van-button>
    </div>
  </div>
</template>

<script>
import {formatterDate} from '@/utils/utils'
import resLabel from './res-label'
import {IdentityCodeValid} from "../../utils/utils";
export default {
  data () {
    return {
      dgwId: '',
      dwId: '',
      branchId: '',
      dxzId: '',
      id: '',
      readonly: false,
      isEdit: false,
      branchShow: false,
      labelShow: false,
      label: '',
      oldLabels: [],
      labelStr: '',
      delLabels: [],
      partyTypeShow: false,
      joinDateShow: false,
      partyStatusShow: false,
      postShow: false,
      correctionDateShow: false,
      joinBranchDateShow: false,
      childrenVisible: false,
      partyTypeList: [{value: 1, label: '正式党员'}, {value: 2, label: '预备党员'}],
      partyStatusList: [{value: 0, label: '正常'}, {value: 1, label: '停止'}],
      minDate: new Date(1930, 0, 1),
      dataForm: {
        id: '',
        orgId: '',
        userId: '',
        userName: '',
        age: '',
        sex: '',
        mobile: '',
        idNumber: '',
        branchId: '',
        branchName: '',
        branchDepth: '',
        partyType: '',
        partyStatus: '',
        joinDate: '',
        postName: '',
        postId: '',
        isFlow: 0,
        correctionDate: '',
        joinBranchDate: '',
        educationStr: '',
        relationId: ''
      },
      checkedValue: [],
      childrenValue: [],
      showInfo: false,
      branchList: [],
      branchNames: {
        text: 'name',
        value: 'id',
        children: 'branchVOS'
      },
      labelList: [],
      postList: [],
      firstValue: [],
      secondValue: [],
    }
  },
  components: {
    resLabel
  },
  mounted () {
    this.dgwId = this.$route.query.dgwId
    this.dwId = this.$route.query.dwId
    this.branchId = this.$route.query.branchId
    this.dxzId = this.$route.query.dxzId
    this.dataForm.branchName = this.$route.query.branchName
    console.log(this.$route.query)
    this.init()
    this.getLabelList()
  },
  methods: {
    init () {
      if(this.$route.query.id) {
        this.id = this.$route.query.id
        this.readonly = JSON.parse(this.$route.query.readonly)
      }
      this.getPartyBranchList()
      if(this.id) {
        this.$http({
          url: this.$http.adornUrl(`/wxapp/party/info`),
          method: 'get',
          params: this.$http.adornParams({
            id:this.id
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.postValue = [data.appParty.post]
            this.initOrgValue = data.appParty.orgIds
            this.branchId = data.appParty.branchId

            // if(sessionStorage.getItem('depth') == 4) {
              this.dgwId = data.appParty.dgwId
              this.dwId = data.appParty.dwId ? data.appParty.dwId : null
              this.branchId = data.appParty.branchId ? data.appParty.branchId : null
              this.dxzId = data.appParty.dxzId ? data.appParty.dxzId : null
              this.dataForm.branchId = [this.dgwId,this.dwId,this.branchId,this.dxzId]
            // }
            // else if(sessionStorage.getItem('depth') == 5) {
            //   this.dgwId = data.appParty.dgwId
            //   this.dwId = data.appParty.dwId ? data.appParty.dwId : null
            //   this.branchId = data.appParty.branchId ? data.appParty.branchId : null
            //   this.dxzId = data.appParty.dxzId ? data.appParty.dxzId : null
            //   this.dataForm.branchId = [this.dwId,this.branchId,this.dxzId]
            // }
            this.dataForm.branchId = data.appParty.lowestId
            this.dataForm.branchName = data.appParty.branchName
            this.dataForm.userId = data.appParty.userId
            this.dataForm.userName = data.appParty.userName
            this.dataForm.idNumber = data.appParty.idNumber
            this.dataForm.mobile = data.appParty.mobile
            this.dataForm.isFlow = data.appParty.isFlow
            this.dataForm.partyType = data.appParty.partyType
            this.dataForm.joinDate = data.appParty.joinDate
            this.dataForm.correctionDate = data.appParty.correctionDate
            this.dataForm.partyStatus = data.appParty.partyStatus
            this.dataForm.postId = data.appParty.post
            this.dataForm.postName = data.appParty.postName
            this.label = data.appParty.labels
            this.oldLabels = data.appParty.labels ? data.appParty.labels.split(',') : []
            this.labelStr = data.appParty.labelStr
            this.dataForm.joinBranchDate = data.appParty.joinBranchDate
            this.dataForm.educationStr = data.appParty.educationStr
            this.dataForm.branchDepth = data.appParty.depth
            this.dataForm.relationId = data.appParty.relationId
            // if(data.appParty.photo) {
            //   data.appParty.photoList = data.appParty.photo.split(',')
            //   this.fileList = data.appParty.photoList.map(item => {
            //     return {
            //       fileType: 'image',
            //       id: this.dataForm.id,
            //       name: '',
            //       url: item
            //     }
            //   })
            // }
            // this.dataForm.content = data.appParty.des
            this.getUserId()
            this.getPartyPost()
          } else {
            this.$message.error(data.msg)
          }
        })
      }
    },
    getUserId (value) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/userByIdNumber'),
        method: 'get',
        params: this.$http.adornParams({
          'idNumber': this.dataForm.idNumber,
          'orgId': this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (data.userInfoList.length !== 0) {
            this.checkIdNumber = true
            this.showInfo = true
            this.dataForm.userId = data.userInfoList[0].id
            this.dataForm.userName = data.userInfoList[0].name
            this.dataForm.mobile = data.userInfoList[0].mobile
            this.dataForm.sex = data.userInfoList[0].sex
            this.dataForm.age = data.userInfoList[0].age
          } else {
            this.checkIdNumber = false
          }
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    typeConfirm () {
      this.labelShow = false
    },
    // 获取党组织
    getPartyBranchList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/partyBranch/branchTree'),
        method: 'get',
        params: this.$http.adornParams({
          orgId: this.$globalData.userInfo.orgId.toString(),
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.branchList = data.tree
        } else {
          this.branchList = []
          this.$message.error(data.msg)
        }
      })
    },
    branchChange({value, selectedOptions, tabIndex }) {
      selectedOptions.map(i => {
        if (i.depth == 1) this.dgwId = i.id
        if (i.depth == 2) this.dwId = i.id
        if (i.depth == 3) this.branchId = i.id
        if (i.depth == 4) this.dxzId = i.id
      })
      this.dataForm.branchId = value
      this.dataForm.branchName = selectedOptions.map((option) => option.name).join('/')
      this.dataForm.branchDepth = selectedOptions.map((item) => item.depth)[selectedOptions.map((item) => item.depth).length - 1]
    },
    handleBranchClose() {
      this.branchShow = false
      // this.branchChange()
      this.getPartyPost()
    },
    // 获取党员职务
    getPartyPost(value,depth) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/party/partyPost'),
        method: 'get',
        params: this.$http.adornParams({
          depth: this.dataForm.branchDepth
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.postList = data.list
        }else {
          this.$message(data.msg)
        }
      })
      // if(sessionStorage.getItem('depth') == 4) {
      //   this.dgwId = value[0]
      //   this.dwId = value[1]
      //   this.branchId = value[2]
      //   this.dxzId = value[3]
      // }else if(sessionStorage.getItem('depth') == 5) {
      //   this.dgwId = ''
      //   this.dwId = value[0]
      //   this.branchId = value[1]
      //   this.dxzId = value[2]
      // }
    },
    onConfirm(val,type) {
      if(type === 1) {
        this.dataForm.partyType = val.value
        this.partyTypeShow = false
      }else if(type === 2) {
        this.dataForm.partyStatus = val.value
        this.partyStatusShow = false
      }else if(type === 3) {
        this.dataForm.postName = val.name
        this.dataForm.postId = val.id
        this.postShow = false
      }else if(type === 4) {
        this.dataForm.joinDate = formatterDate(val)
        this.joinDateShow = false
      }else if( type === 5) {
        this.dataForm.correctionDate = formatterDate(val)
        this.correctionDateShow = false
      }else if(type === 6) {
        this.dataForm.joinBranchDate = formatterDate(val)
        this.joinBranchDateShow = false
      }
    },
    onChangeIsFlow(val) {
      console.log(val);
    },
    // 获取党员标签
    getLabelList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/party/label'),
        method: 'get',
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.labelList = data.labels
        }
      })
    },
    change(val) {
      this.firstValue = val
      this.labelStr = val.map(function(item,index){
        return item.label}).concat(this.secondValue.map(item => {
          return item.label
      })).join(',')
      this.label = val.map(item => {
        return item.value}).concat(this.secondValue.map(item => {
          return item.value
        })).join(',')
      this.labelShow = true
    },
    childrenChange (val){
      this.secondValue = val
      this.labelStr = val.map(function(item,index){
        return item.label}).concat(this.firstValue.map(item => {
          return item.label
      })).join(',')
      this.label = val.map(item => {
        return item.value}).concat(this.firstValue.map(item => {
          return item.value
        })).join(',')
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    toggleChildren(index) {
      this.$refs.childrenBoxes[index].toggle();
    },
    //标签信息改变
    // selectLabel (e) {
    //   console.log('信息改变',e);
    //   // const {selectList, labelCondition} = e
    //   // this.dataForm.labels = selectList
    //   // this.dataForm.labelCondition = labelCondition + ''
    //   // this.control.communityLabelCascader = false
    //   // this.onPullDownRefresh()
    // },
    // 编辑
    handleIsEdit() {
      this.isEdit = true
      this.readonly = false
    },
    // 获取修改时被修改掉的标签
    getDeleteLabels() {
      this.label = this.label && this.label.length > 0 ? this.label.split(',') : []
      this.oldLabels.map(item => {
        if(this.label.indexOf(item) === -1) {
          this.delLabels.push(item)
        }
      })
    },
    submit () {
      this.getDeleteLabels()
      if (!this.dataForm.idNumber ) {
        return this.$toast.fail('失败:用户证件号不能为空');
      } else {
        if (!IdentityCodeValid(this.dataForm.idNumber)){
          return this.$toast.fail('失败:请输入正确证件号');
        }
      }
      if (!this.dataForm.userId ) {return this.$toast.fail('失败:请输入正确的社区用户证件号');}
      if (!this.dataForm.branchName ) {return this.$toast.fail('失败:请选择所属支部');}
      if (!this.dataForm.postName ) {return this.$toast.fail('失败:请选择党内职务');}
      if (!this.labelStr ) {return this.$toast.fail('失败:请选择党员标签');}
      if (this.dataForm.partyType && this.dataForm.partyType == '1') {
        if (!this.dataForm.joinDate ) {return this.$toast.fail('失败:请选择入党日期');}
      } else {
        if (!this.dataForm.correctionDate) {return this.$toast.fail('失败:请选择转正日期');}
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/party/save'),
        method: 'post',
        data: this.$http.adornData({
          'street': this.$globalData.userInfo.street || '',
          'id': this.id || undefined,
          'community': this.$globalData.userInfo.orgId,
          'userId': this.dataForm.userId,
          'idNumber': this.dataForm.idNumber,
          'mobile': this.dataForm.mobile,
          'isFlow': this.dataForm.isFlow,
          'partyType': this.dataForm.partyType,
          'joinDate': this.dataForm.joinDate,
          'correctionDate': this.dataForm.correctionDate || '',
          'partyStatus': this.dataForm.partyStatus,
          'relationId': this.dataForm.relationId,
          'post': this.dataForm.postId,
          'labels': this.label.toString(','),
          'dgwId': this.dgwId,
          'dwId': this.dwId,
          'branchId': this.branchId,
          'dxzId': this.dxzId,
          'joinBranchDate': this.dataForm.joinBranchDate,
          'delLabels': this.id ? this.delLabels.toString() : undefined
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success({
            message: this.id ? '修改成功' :'新增成功',
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                // this.isEdit = true;
                this.$toast.clear()
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
    handleEidt() {
      this.submit()
      this.isEdit = false
      this.readonly = true

    },
    moveOutParty(url){
      this.$http({
        url: this.$http.adornUrl(`/wxapp/party/${url}`),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId || 29,
          id: url == 'moveOut' ? '' : this.id,
          relationId: url == 'moveOut' ? this.dataForm.relationId : '',
          userId: url == 'moveOut' ? this.dataForm.userId : ''
        })
      }).then(({data})=> {
        if (data.code == 0) {
          let list = data.dicts
          this.postList =list;
          this.$toast.clear()
          this.$toast.success("操作成功!");
          setTimeout(() => {
            this.$router.go(-1)
          }, 1500)
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.field-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .edit {
    font-size: 28px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    color: #F24C48;
  }
}

.title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 750px;
  height: 88px;
  background: #FFFFFF;
  border-radius: 10px 10px 0px 0px;
  padding: 22px 30px;
  margin-top: 24px;
  .title {
    font-size: 32px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    color: #333333;
  }
  .check-more {
    font-size: 24px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: #999999;
  }
}
.info-items {
  width: 750px;
  // height: 362px;
  background: #FFFFFF;
  padding: 0 30px;
  .info-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 96px;
    border-bottom: 2px solid rgba(0,0,0,0.1000);
    .left-title {
      display: flex;
      font-size: 28px;
      font-weight: 500;
      font-family: PingFangSC-Medium;
      .communityName {
        color: #666666;
      }
      .partyType {
        color: #333333
      }
    }
    .right-title {
      font-size: 24px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #999999;
    }
  }
  .info-mid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    // border-bottom: 2px solid rgba(0,0,0,0.1000);
    .left-info-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 424px;
      height: 136px;
      .headImg {
        width: 100px;
        height: 136px;
      }
      .detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 136px;
        .name {
          font-size: 28px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          color: #333333;
          .post {
            color: #666666;
          }
        }
        .number {
          font-size: 28px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          color: #666666;
        }
      }
    }
    .phoneIcon {
      width: 40px;
      height: 40px;
    }
  }
}
.footer-btns {
  padding: 0 30px;
  width: 750px;
  height: 88px;
  margin-top: 146px;
  margin-bottom: 40px;
  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn-items {
      width: 324px;
      height: 88px;
      background: #F24C48;
    }
    .delete {
      width: 324px;
      height: 88px;
      background: linear-gradient(360deg, #FFFFFF 0%, #F5F5F5 100%);
      color: #F24C48;
      border: 2px solid #F24C48;
    }
  }
}
// 是否对鼠标事件做出反应
.poinrt{
  pointer-events: none;
}

.typeCheck {
  display: flex;
  height: 150px;
  line-height: 115px;
  padding-left: 40px;
  span {
    display: block;
    flex: 1;
    font-size: 30px;
  }
  span:nth-child(1) {
    font-size: 28px;
    color: #969799;
  }
  span:nth-child(3) {
    margin-right: -187px;
    font-size: 28px;
    color: #576b95;
  }
}
.typeCheckBox .van-cell__title {
  margin-left: 20px;
}
.typeCheckBox .van-icon {
  position: absolute;
  right: 40px;
  bottom: 45%;
}
.children {
  margin-left: 50px;
}
.children .van-cell__value {
  margin-left: 10px;
}
</style>
